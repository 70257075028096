import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import PromotionBanner from '../PromotionBanner/PromotionBanner';
import 'swiper/css';
import 'swiper/css/navigation';

type Props = {
  promotions: any;
};

const Promotions: React.FC<Props> = ({ promotions }: Props) => {
  return (
    <>
      <div className="w-full">
        <Swiper
          a11y={false}
          modules={[Navigation]}
          watchSlidesProgress
          navigation={{
            disabledClass: 'swipper-navigation-disabled',
          }}
        >
          {promotions.map((promotion, index) => (
            <SwiperSlide key={index}>
              <PromotionBanner promotion={promotion} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Promotions;
