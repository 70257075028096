import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';

type Props = {
  show: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
};

const DialogWishlist: React.FC<Props> = ({
  show = false,
  onAccept,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    show && (
      <div
        className="fixed z-30 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-2 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
          <span className="sm:inline-block sm:align-middle sm:h-screen" />
          <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-xs">
            <div className="mt-2 w-full flex flex-row justify-end">
              <XIcon
                onClick={onCancel}
                className="w-6 mx-2 text-black cursor-pointer"
              />
            </div>
            <div className="bg-white p-6 pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-bold text-gray-900"
                    id="modal-title"
                  >
                    {t('common:wishlist.titlePopUp')}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {t('common:wishlist.descriptionPopUp')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse">
              {onCancel && (
                <button
                  onClick={onCancel}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-2 border-black shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-0 ml-3 w-auto text-sm"
                >
                  <p className="font-semibold">{t('common:wishlist.cancel')}</p>
                </button>
              )}
              {onAccept && (
                <button
                  onClick={onAccept}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 w-auto text-sm"
                >
                  <p className="font-semibold">{t('common:wishlist.ok')}</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DialogWishlist;
