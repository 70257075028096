import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { PlayIcon } from '@/icons/play';
import { BookmarkIcon, CheckIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import useWhislist from '@/hooks/useWhislist';
import awsImageLoader from '@/utils/awsImageLoader';
import { isSeries } from '@/utils/index';

type Props = {
  promotion: any;
};

const PromotionBanner: React.FC<Props> = ({ promotion }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const { isOnWhislist, toggleContent } = useWhislist();

  const getPromotionLink = () => {
    const { slug, content_type } = promotion.contents;
    let link = isSeries(content_type) ? `/series/${slug}` : `/movie/${slug}`;
    if (promotion.is_channel) {
      link = `/channels?selected=${(promotion.channels || {})._id}`;
    }
    return link;
  };

  return (
    <div className="relative cursor-pointer">
      <div className="hidden md:block">
        <Image
          loader={awsImageLoader}
          src={promotion.image_name}
          layout="responsive"
          width={1920}
          height={720}
          priority
          alt={promotion.title}
        />
      </div>
      <div className="relative flex items-center justify-center md:hidden h-52 rounded-lg overflow-hidden bg-black m-4">
        <Image
          loader={awsImageLoader}
          src={promotion.image_name}
          layout="fill"
          priority
          alt={promotion.title}
        />
        <Link href={getPromotionLink()} locale={locale}>
          <div className="bg-black bg-opacity-10 backdrop-blur z-10 rounded-full w-16 h-16 flex items-center justify-center">
            <PlayIcon className="w-5" />
          </div>
        </Link>
      </div>
      <div className="hidden md:block absolute left-0 bottom-0 right-0 w-full text-white bg-gradient-to-t from-[#000000cc] to-transparent z-50">
        <div className="container mx-auto flex flex-col py-8 px-16 xl:px-0">
          <div className="font-semibold text-2xl md:text-3xl">
            {promotion.title}
          </div>
          <div className="flex space-x-2 py-4">
            {promotion.is_channel ? (
              <div className="tag">{t('channel')}</div>
            ) : (
              <>
                <div className="tag">
                  {promotion.contents[`genre_${locale}`]}
                </div>
                <div className="tag">{promotion.contents.production_year}</div>
                <div className="tag">
                  {promotion.contents[`country_${locale}`]}
                </div>
              </>
            )}
          </div>
          <div className="flex space-x-3">
            <Link href={getPromotionLink()} locale={locale}>
              <button className="rounded-lg bg-accent px-4 py-2 flex items-center space-x-2 text-sm font-semibold">
                <PlayIcon className="w-4" />
                <span>{t('play')}</span>
              </button>
            </Link>

            {!promotion.is_channel && (
              <button
                className="rounded-lg bg-white bg-opacity-10 px-4 py-2 flex items-center space-x-1 text-sm font-semibold "
                onClick={(event) => toggleContent(event, promotion.contents)}
              >
                {!isOnWhislist(promotion.contents) ? (
                  <>
                    <BookmarkIcon className="w-6" />
                    <span>{t('queue')}</span>
                  </>
                ) : (
                  <>
                    <CheckIcon className="w-6" />
                    <span>{t('queued')}</span>
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBanner;
