export const PlayIcon = ({ className }) => (
  // <svg className={className} fill="none" viewBox="0 0 24 24">
  //   <path
  //     stroke="currentColor"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //     strokeWidth="1.5"
  //     d="M18.25 12L5.75 5.75V18.25L18.25 12Z"
  //   />
  // </svg>
  <svg
    className={className}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3435 9.13221L2.00362 15.1317C1.12612 15.6403 0 15.0245 0 13.9991V2.00009C0 0.976339 1.1245 0.358839 2.00362 0.869089L12.3435 6.86859C12.5431 6.98254 12.709 7.14726 12.8244 7.34603C12.9398 7.5448 13.0006 7.77056 13.0006 8.0004C13.0006 8.23025 12.9398 8.45601 12.8244 8.65478C12.709 8.85355 12.5431 9.01826 12.3435 9.13221Z"
      fill="currentColor"
    />
  </svg>
);
