/* eslint-disable @typescript-eslint/ban-ts-comment */
import Api from '@/services/Api';
import React from 'react';
import { useContext } from 'react';
import { useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { convertLanguageCodeToName, arrayJoinKeyName } from 'utils';
import { event as eventGA } from 'utils/gtag';
import { AppContext } from '@/contexts/app.context';

const useWhislist = () => {
  const [session] = useSession();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { setIsWishlistModalOpen } = useContext(AppContext);
  const dataLayer = (content) => ({
    brand: 'Butaca TV',
    endpoint_group: 'Desktop',
    subbrand: 'Butaca TV',
    endpoint: 'Butaca TV',
    // @ts-ignore
    user_id: session ? session.user.id : '',
    language: convertLanguageCodeToName(router.locale),
    film_id: content._id,
    film_name: content.film_title,
    film_brand: 'Butaca TV',
    film_category: 'film',
    film_genre: content.genre_en,
    film_director: content.director,
    film_country: arrayJoinKeyName(content.countries, 'name_en'),
    film_duration: content.runtime,
    film_url: `/movie/${content.slug}`,
  });
  const { data: profileData } = useQuery(
    'profile',
    () => (session ? Api.get('v2/profile') : { queues: [] }),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    },
  );

  const addQueueMutation = useMutation(
    (content: any) => Api.post(`v2/queues/${content._id}`),
    {
      onMutate: async (newContent) => {
        await queryClient.cancelQueries('profile');
        const previousProfile = queryClient.getQueryData('profile');
        queryClient.setQueryData('profile', (old: any) => ({
          ...old,
          queues: [...old.queues, { id: newContent._id }],
        }));
        return { previousProfile };
      },
      onError: (err, newContent, context: any) => {
        queryClient.setQueryData('profile', context.previousProfile);
      },
      onSettled: () => {
        queryClient.invalidateQueries('profile');
      },
    },
  );

  const removeQueueMutation = useMutation(
    (content: any) => Api.delete(`v2/queues/${content._id}`),
    {
      onMutate: async (newContent) => {
        await queryClient.cancelQueries('profile');
        const previousProfile = queryClient.getQueryData('profile');
        queryClient.setQueryData('profile', (old: any) => ({
          ...old,
          queues: old.queues.filter((content) => content.id !== newContent._id),
        }));
        return { previousProfile };
      },
      onError: (err, newContent, context: any) => {
        queryClient.setQueryData('profile', context.previousProfile);
      },
      onSettled: () => {
        queryClient.invalidateQueries('profile');
      },
    },
  );

  const isOnWhislist = React.useCallback(
    (content) => {
      if (!profileData?.queues?.some((q) => q.id === content._id)) {
        return false;
      }
      return true;
    },
    [profileData],
  );

  const toggleContent = React.useCallback(
    async (event, content) => {
      event.preventDefault();
      event.stopPropagation();
      if (session) {
        if (!profileData?.queues?.some((q) => q.id === content._id)) {
          addQueueMutation.mutate(content);
          eventGA({
            event: 'fil_add_to_list',
            data: dataLayer(content),
          });
        } else {
          removeQueueMutation.mutate(content);
          eventGA({
            event: 'fil_remove_to_list',
            data: dataLayer(content),
          });
        }
      } else {
        setIsWishlistModalOpen(true);
      }
    },
    [session, profileData, addQueueMutation, removeQueueMutation, router],
  );

  return { isOnWhislist, toggleContent };
};

export default useWhislist;
