// type input parameters
interface LoaderInput {
  src: string;
  width: number;
  quality: number;
}

const myLoader = ({ src, width, quality = 75 }: LoaderInput): string => {
  return `https://serve.butaca.tv/${src}?w=${width}&q=${quality}`;
};

export default myLoader;
