/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import Api from '@/services/Api';
import { ContentType, Language } from '@types';
import { useRouter } from 'next/router';
import { useInfiniteQuery } from 'react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ContentCard from '../ContentCard/ContentCard';
import Link from 'next/link';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { convertLanguageCodeToName } from 'utils';
import { useSession } from 'next-auth/client';
import { event } from 'utils/gtag';

import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  playlist: any;
  contentType?: ContentType;
};

const MAX_PRIORITY_PRELOAD_IMAGES = isMobile ? 2 : 6;

const Playlist: React.FC<Props> = ({ contentType, playlist }: Props) => {
  const { _id } = playlist;
  const { locale, push } = useRouter();
  const [swipper, setSwipper] = useState<any>();
  const [session] = useSession();
  const [showNavigationControls, setShowNavigationControls] =
    useState<boolean>(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataLayer = {
    event: 'navigation_interaction',
    data: {
      brand: 'Butaca TV',
      endpoint_group: 'Desktop',
      subbrand: 'Butaca TV',
      endpoint: 'Butaca TV',
      // @ts-ignore
      user_id: session ? session.user.id : '',
      language: convertLanguageCodeToName(locale),
      playlist_name: playlist.name_en,
      playlist_id: playlist._id,
    },
  };
  // TODO: add limit query param to 5 on mobile devices.
  const {
    data: contents,
    isLoading,
    error,
    fetchNextPage,
  } = useInfiniteQuery(
    ['playlists', contentType || 'all', _id],
    async ({ pageParam = 1 }) => {
      try {
        return await Api.get(
          `v2/homepage_playlists/${_id}?page=${pageParam}${
            contentType ? `&content_type=${contentType}` : ''
          }`,
        );
      } catch (err) {
        if (err.status >= 500) {
          throw new Error('Error request');
        }
      }
    },
    {
      staleTime: 60000 * 5,
      getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    },
  );

  if (error) {
    push('/500');
  }
  const handleActiveIndexChange = React.useCallback(
    (swiper) => {
      switch (swiper.swipeDirection) {
        case 'next':
          fetchNextPage();
          event(dataLayer);
          break;
        default:
          break;
      }
    },
    [dataLayer, fetchNextPage],
  );
  const movePrevSlide = useCallback(
    (evt) => {
      evt.stopPropagation();
      swipper.slidePrev();
      event(dataLayer);
    },
    [swipper],
  );

  const moveNextSlide = useCallback(
    (evt) => {
      evt.stopPropagation();
      swipper.slideNext();
      if (swipper.isEnd) fetchNextPage();
      event(dataLayer);
    },
    [swipper, fetchNextPage],
  );

  useEffect(() => {
    if (swipper && contents) {
      const total = contents.pages[0].totalDocs;
      const visibleSlides = swipper.visibleSlides?.length;
      if (total === visibleSlides) {
        setShowNavigationControls(false);
      }
    }
  }, [swipper, contents]);

  console.log(contents?.pages);

  return (
    <div className="min-h-[250px] md:min-h-[400px]">
      <div className="flex flex-row justify-between items-end">
        <Link href={`/collections/${playlist.slug}`}>
          <div className="font-bold text-2xl mt-8">
            {locale === Language.EN ? playlist.name_en : playlist.name_es}
          </div>
        </Link>
        {showNavigationControls && (
          <div className="hidden sm:flex flex-row justify-between items-center">
            <ChevronLeftIcon
              onClick={movePrevSlide}
              className="w-8 h-6 cursor-pointer channel-swipper-button-prev"
            />
            <ChevronRightIcon
              onClick={moveNextSlide}
              className="w-8 h-6 cursor-pointer channel-swipper-button-next"
            />
          </div>
        )}
      </div>
      <div>
        <Swiper
          slidesPerView="auto"
          modules={[Pagination]}
          spaceBetween={20}
          onSwiper={(swipper) => setSwipper(swipper)}
          className="!pt-4 lg:!-ml-8 lg:!pl-7 lg:!w-[calc(100%+2rem)]"
          onActiveIndexChange={handleActiveIndexChange}
          watchSlidesVisibility
        >
          {!isLoading &&
            contents?.pages.map((page) =>
              page.docs.map((content, index) => (
                <SwiperSlide key={content._id} className="!w-auto">
                  <ContentCard
                    playlist={{
                      _id: playlist._id,
                      is_channel_playlist: playlist?.is_channel_playlist,
                      name_en: playlist.name_en,
                    }}
                    content={content}
                    loadPriority={index < MAX_PRIORITY_PRELOAD_IMAGES}
                  />
                </SwiperSlide>
              )),
            )}
        </Swiper>
      </div>
    </div>
  );
};

export default Playlist;
